import axios from "axios";

const sendEmail = async (
  formik,
  form_service_type_budget,
  form_service_type,
  form_service_type_projectBegin,
  form_service_type_hiringDecision,
  newRow,
  ipAddress,
) => {
  const response = await axios.post("/api/send-email", {
    subject: "New Lead On Techcelerant",
    toEmail: "leads@techcelerant.com",
    text: formik.values.name,
    html_text: `
    <div style="font-family: Arial, sans-serif; border: 1px solid #cccccc; padding: 20px; max-width: 600px; margin: 0 auto;">
      <h2 style="color: #333333;">Customer Details:</h2>
      <ul style="list-style-type: none; padding: 0;">
      <li style="color: #333333;">Name: ${formik.values.name}</li>
        <li style="color: #333333;">Phone Number: ${formik.values.phone}</li>
        <li style="color: #333333;">Email: ${formik.values.email}</li>
        <li style="color: #333333;">Business Name: ${formik.values.business}</li>
        <li style="color: #333333;">Budget: ${form_service_type_budget}</li>
        <li style="color: #333333;">Project Begins: ${form_service_type_projectBegin}</li>

        <li style="color: #333333;">Hiring Decision: ${form_service_type_hiringDecision}</li>

        <li style="color: #333333;">Best Time To Call: ${formik.values.timeToCall}</li>

        <li style="color: #333333;">Services Selected: ${form_service_type}</li>
        <li style="color: #333333;">Message: ${formik.values.message}</li>
        <li style="color: #333333;">Client IP: ${ipAddress ? ipAddress : "check"}</li>

      </ul>
      <p style="color: #333333;">Copyrights Techcelerant</p>
    </div>
  `,
  });

  return response;
};

const sendEmailToCustomer = async (
  formik,
  form_service_type_budget,
  form_service_type,
  newRow,
) => {
  const response = await axios.post("/api/send-email-customer", {
    subject: "Reply from Techcelerant",
    toEmail: formik.values.email,
    text: formik.values.name,
    html_text: `
      <div style="font-family: Arial, sans-serif; border: 1px solid #cccccc; padding: 20px; max-width: 600px; margin: 0 auto;">
        <p style="color: #333333;">Dear ${formik.values.name}, </p>
        <p style="color: #333333;">Welcome to the Techcelerant family! We are thrilled to have you on board and excited to embark on this digital journey together with you.
        </p>

        <p style="color: #333333;">
        Thank you for submitting your requirement on Techcelerant.com and choosing us to be your partner in achieving your digital goals.
        </p>

        <p style="color: #333333;">One of our representatives will be contacting you shortly.</p>
        <p style="color: #333333;">Regards,</p>
        <p style="color: #333333;">Team Techcelerant</p>

      </div>
    `,
  });
  return response;
};

const sendDataToSheets = async (newRow) => {
  const responseGgheets = await axios.post("/api/google-sheets", { newRow });
  const responseGgheets2 = await axios.post("/api/google-sheet-api", {
    newRow,
  });

  return { responseGgheets, responseGgheets2 };
};

export { sendEmail, sendDataToSheets, sendEmailToCustomer };
