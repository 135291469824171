import Select, { components } from "react-select";
import React from "react";

import AddIcon from "@mui/icons-material/Add";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
//ask
const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <span className="bg-black p-1 rounded-sm mr-2 cursor-pointer">
        <AddIcon />
      </span>
      {/* <div className='flex items-center' > */}
      {/* <img src="./select-plus.svg" className='h-[14px] w-[14px] mr-2' /> */}
      {props.data.label}

      {/* </div> */}
    </components.Option>
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownIcon
        style={{
          transform:
            props.selectProps.menuIsOpen && props.isMulti === true
              ? "rotate(180deg)"
              : "rotate(0deg)",
          transition: "transform 0.2s",
        }}
      />

      {props.selectProps.menuIsOpen && <ArrowBackIcon />}
    </components.DropdownIndicator>
  );
};

const CustomOption2 = (props) => {
  return <components.Option {...props}>{props.data.label}</components.Option>;
};

const SelectComponent2 = ({
  className,
  isMobile,
  defaultValue,
  onChange,
  options,
  placeholder,
  instanceId,
  label,
  multi,
  selectWidth,
  required,
  mobileWidth,
  formik,
  value,
  formikTouched,
  formikError,
  stringValue,
}) => {
  const placeHolderLength = placeholder.length ? placeholder.length : 1;
  const placeHolderCalculate = placeHolderLength * 10 + "px";
  const mobileW = mobileWidth ? mobileWidth : "21.5rem";

  const customStyles = {
    placeholder: (baseStyles) => ({
      color: "gray",
      fontWeight: "400",
    }),

    valueContainer: (baseStyles, state) => ({
      ...baseStyles,
      alignItems: "center",
      backgroundColor: "transparent",
      color: "white",
      /*borderLeft: "none",
      borderRight: "none",
      borderTop: "none",
      borderBottom: `4px solid var(--techquisite-primary-color)`,*/
      display: "flex",
      flex: 1,
      flexWrap: "nowrap",
      position: "relative",
      overflow: "hidden",
      overflowX: "scroll",
      overflowY: "hidden",
      padding: "2px 8px",
      boxSizing: "border-box",
      WebkitOverflowScrolling: "touch",
      // Hide scrollbar
      "&::-webkit-scrollbar": {
        width: "10px",
        height: "3px",
      },

      // '-ms-overflow-style': 'none', // For IE and Edge
      // 'scrollbar-width': 'none', // For Firefox
      // Add more styles as needed
      boxShadow: state.isFocused ? null : null,

      "&:hover": {
        borderColor: "transparent",
      },
    }),

    multiValueRemove: (baseStyles) => ({
      ...baseStyles,
      ":hover": {
        backgroundColor: "black",
        color: "white",
        borderRadius: "0px 20px 20px 0px",
        borderColor: "transparent",
      },
    }),

    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: state.isFocused ? "gray" : "white",
      backgroundColor: "transparent",
      color: "white",
      border: '1px solid rgba(255, 255, 255, .1)',
      width: isMobile ? mobileW : selectWidth,
      outline: "none",
      fontSize: "13px",
      marginTop: "-5px",
      marginLeft: "-6px",
      fontFamily: "inherit",
      boxShadow: state.isFocused ? null : null,

      "&:hover": {
        borderColor: "transparent",
      },
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        minWidth: "auto",
        borderRadius: "20px",
        color: "white",
        background: "linear-gradient(to bottom, #624da0, #984d7d)",
      };
    },
    singleValue: (styles) => {
      return {
        ...styles,
        color: "white",
      };
    },

    multiValueLabel: (styles) => ({
      ...styles,
      color: "white",
    }),

    menu: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: "gray",
    }),

    menuList: (style) => {
      return {
        ...style,

        "&:hover": {
          borderColor: "transparent",
        },
      };
    },
    option: (baseStyles, { isFocused, isSelected }) => ({
      ...baseStyles,
      background: isSelected
        ? "darkgray"
        : isFocused
          ? multi === true
            ? "linear-gradient(to bottom, #29a598, #1b746b)"
            : "linear-gradient(to bottom, #29a598, #1b746b)"
          : "white",
      color: isFocused ? "white" : "gray",
      fontSize: "13px",

      boxShadow: isFocused ? null : null,
      "&:hover": {
        borderColor: "transparent",
      },
    }),
  };

  return (
    <div
      className={`${className} ${formikError && formikTouched && (formik.values[stringValue] === "" || formik.values[stringValue] === null) ? "cntforms" : ""}`}
    >
      {/* {formik.touched[stringValue] && formik.errors[stringValue] &&
 (formik.values[stringValue] === '' || formik.values[stringValue] === null) && (
  <div className="error">{formik.errors[stringValue]}</div>
)} */}

      <span
        style={{
          "--placeholder-text": "-8px",
        }}
        className={`  ${required === true ? "select-group" : ""}   `}
      >
        <Select
          components={{
            Option: multi === true ? CustomOption : CustomOption2,
            DropdownIndicator,
          }}
          styles={customStyles}
          defaultValue={defaultValue}
          // onChange={onChange}

          onChange={onChange}
          options={options}
          isSearchable
          placeholder={placeholder}
          instanceId={instanceId}
          aria-label={label}
          isMulti={multi}
          required={required}
          value={value}
          closeMenuOnSelect={!multi}
          // tabSelectsValue={false}
          // backspaceRemovesValue={false}
          // hideSelectedOptions={false}
          // blurInputOnSelect={false}
          onMenuClose={() => {
            if (
              formik.values[stringValue] === "" ||
              formik.values[stringValue] === null
            ) {
              formik.setFieldTouched(stringValue, true);
            } else {
              // This else block to set it to false might not be necessary or recommended,
              // as Formik uses the touched state to indicate if a field has been interacted with.
              formik.setFieldTouched(stringValue, false);
            }
          }}
        />
      </span>
    </div>
  );
};

export default SelectComponent2;
