import { useFormik } from "formik";
import * as Yup from "yup"; // Assuming you have Yup for validation

const useDynamicFormik = (initialValues, validationSchema, onSubmit) => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return formik;
};

export default useDynamicFormik;
