import { Fragment, useState, useEffect, useRef } from "react";
import toast, { Toaster }  from "react-hot-toast";
import axios from "axios";
import { Formik, useFormik } from "formik";
import { useMediaQuery } from "@mui/material";
import { imageScale } from "@/src/utils/motion";
import useMouseOver from "@/src/hooks/useMouseOver";
import { options,  BudgetValues, initialValues, textSliderData, BeginProject, hiringDecision, } from "@/src/datas/formData";
import { formValidationSchema } from "@/src/utils/formValidations";
import InputComponent2 from "@/src/components/InputComponent2";
import { sendEmail,sendEmailToCustomer,sendDataToSheets } from "@/src/components/lib/sendEmail";
import timeStampGenerator from "@/src/components/lib/timeStampGenerator";
import useClientIP from "@/src/hooks/useClientIP";
import { useRouter } from "next/router";
import useDynamicFormik from "@/src/hooks/useDynamicFormik";
import UseAnimationIos from "@/src/pages/api/useAnimationIos";
import PhoneInputCustom2 from "@/src/components/PhoneInputCustom2";
import SelectComponent2 from "@/src/components/SelectComponent2";

const SideBar = () => {
  const [form, setForm] = useState({ name: "", email: "", message: "" });

  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };
  const handleSubmitSecond = async () => {
 
    setLoading(true)
    try {
      const sendEmail = await axios.post('/api/send-email', {
        subject: "Query Form From Website",
        toEmail: 'ishariqriaz@gmail.com',
        text: form.name,
        html_text: `
        <div style="font-family: Arial, sans-serif; border: 1px solid #cccccc; padding: 20px; max-width: 600px; margin: 0 auto;">
          <h2 style="color: #333333;">Hello , ${form.name} </h2>
          <p style="color: #333333;">Thank you for your purchase!</p>
          <p style="color: #333333;">Order Details:</p>
          <ul style="list-style-type: none; padding: 0;">

            <li style="color: #333333;">Customer Contact information:  </li>
            <li style="color: #333333;">Customer Name:  ${form.name} </li>
            <li style="color: #333333;">Customer Phone:  ${form.phone} </li>
            <li style="color: #333333;">Customer Email:  ${form.email} </li>
            <li style="color: #333333;">Customer Message:  ${form.message} </li>


          </ul>
          <p style="color: #333333;">If you have any questions, feel free to reply to this email.</p>
          <p style="color: #333333;">Thank you,</p>
          <p style="color: #333333;">Techcelerant</p>
        </div>
      `,
      });
      if(sendEmail.data === 'Success'){
        setLoading(false);
        
      }
      else {
        toast.error("Please Retry again.");
        setLoading(false)
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false)
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();  
    setLoading(true)
    try {
      const sendEmail = await axios.post('/api/send-email', {
        subject: "Query Form From Website",
        toEmail: 'dev.techriffic@gmail.com',
        text: form.name,
        html_text: `
        <div style="font-family: Arial, sans-serif; border: 1px solid #cccccc; padding: 20px; max-width: 600px; margin: 0 auto;">
          <h2 style="color: #333333;">Hello , ${form.name} </h2>
          <p style="color: #333333;">Thank you for your purchase!</p>
          <p style="color: #333333;">Order Details:</p>
          <ul style="list-style-type: none; padding: 0;">

            <li style="color: #333333;">Customer Contact information:  </li>
            <li style="color: #333333;">Customer Name:  ${form.name} </li>
            <li style="color: #333333;">Customer Phone:  ${form.phone} </li>
            <li style="color: #333333;">Customer Email:  ${form.email} </li>
            <li style="color: #333333;">Customer Message:  ${form.message} </li>


          </ul>
          <p style="color: #333333;">If you have any questions, feel free to reply to this email.</p>
          <p style="color: #333333;">Thank you,</p>
          <p style="color: #333333;">Techcelerant</p>
        </div>
      `,
      });
      if(sendEmail.data === 'Success'){
        toast.success("Thank you!, Our representative will contact You.");
        setLoading(false);
        handleSubmitSecond();

      }
      else {
        toast.error("Please Retry again.");
        setLoading(false);
        handleSubmitSecond();
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false)
    }
  };

  //my
  const isMobile = useMediaQuery("(max-width:786px)");
const isPixelActive = useMouseOver();
const router = useRouter;

const [selectedOption, setSelectedOption] = useState(null);
const [selectedOptionBudget, setSelectedOptionBudget] = useState(null);
const [selectProjectBegin, setSelectProjectBegin] = useState(null);
const [selectHiringDecision, setSelectHiringDecision] = useState(null);
const [isFormSubmited, setIsFormSubmited] = useState(false);
const [selectedTime, setSelectedTime] = useState("");
const [recaptchaToken, setRecaptchaToken] = useState("");
const [showCaptchaError, setShowCaptchaError] = useState(false);

const [value, setValue] = useState();
const ipAddress = useClientIP();


const handleTimeChange = (timeZone, timeSlot) => {
  //ask
  const timeToCall = `${timeZone} ${timeSlot?.start ? timeSlot?.start : ""} - ${timeSlot?.end ? timeSlot?.end : ""}`;
  setSelectedTime(timeToCall);
};

const onSubmit = async () => {
  const form_service_type =
    selectedOption?.map((option) => option.label).join(", ") ||
    "";
  const form_service_type_budget = selectedOptionBudget?.value
    ? selectedOptionBudget.value
    : "";

  const form_service_type_projectBegin = selectProjectBegin?.value
    ? selectProjectBegin.value
    : "";

  const form_service_type_hiringDecision = selectHiringDecision?.value
    ? selectHiringDecision.value
    : "not selected";

  try {

    const utcMinus11Timestamp = timeStampGenerator();
    let split_data = utcMinus11Timestamp.split(', ')
let date_str = split_data[0]
let time_str = split_data[1]

const formatPhoneNumber = (phoneNumber) => {
const cleaned = ('' + phoneNumber).replace(/\D/g, '');
const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
if (match) {
    return `(${match[2]}) ${match[3]}-${match[4]}`;
}
return phoneNumber;
};

const formattedPhoneNumber = formatPhoneNumber(formik.values.phone);

    const ipaddress = ipAddress; 



    const newRow = {
      Date: date_str,
       // Time: time_str,
       State: "",
       Source: "Get Started",
       Assigned: "",
       Status: "Fresh",
       Name: formik.values.name,
       Email: formik.values.email,
       Number: formattedPhoneNumber,
       Company: "",
       "Client\nNotes": ` ${formik.values.message ? ('/') : ('')} ${formik.values.message} ${ipAddress ? ('/') : ('')} ${ipAddress}`,
       // Services: form_service_type,
       // Message: formik.values.message,
       // IP: ipAddress,
    };
    setIsFormSubmited(true);
    const gSheets = await sendDataToSheets(newRow);
    formik.setStatus({
      success: true,
      message: "Form submitted successfully",
    });

    if (!isVisible) {
      close(false);
    }
    // toast.success("Thank you!, Our representative will contact You.");
    setIsFormSubmited(true);
    if (!isVisible) {
      close(false);
    }
    
    const emailResponse = await sendEmail(
      formik,
      form_service_type_budget,
      form_service_type,
      form_service_type_projectBegin,
      form_service_type_hiringDecision,
      newRow,
      ipAddress,
    );

    const sendEmailToUser = await sendEmailToCustomer(
      formik,
      form_service_type_budget,
      form_service_type,
      form_service_type_projectBegin,
      form_service_type_hiringDecision,
      newRow,
    );

    if (emailResponse.data === "Success") {
      formik.setStatus({
        success: true,
        message: "Form submitted successfully",
      });

      if (!isVisible) {
        close(false);
      }
      // toast.success("Thank you!, Our representative will contact You.");
      setIsFormSubmited(true);
      if (!isVisible) {
        close(false);
      }
    }
    else {
      formik.setStatus({ success: false, message: "Submission error" });

      toast.error("Please Retry again.");
    }
 
    // }
    // else {
    //     // Handle error
    //     console.error(data.message);
    //     toast.error("Please refill captcha");

    // }
  } catch (error) {
    /* eslint-disable */
    console.log(error.message);
    formik.setStatus({ success: false, message: error.message });
  }
};

const formik = useDynamicFormik(
  initialValues,
  formValidationSchema,
  onSubmit,
);

// if(formik?.status?.success === true) {
//   close
//   return (
//     <div className='bg-white p-10 rounded-xl text-2xl'> Thanks For Filling the form </div>
//   )

// }

const [onScreen, setOnScreen] = useState(false);
const ref = useRef();
const formRef = useRef();
UseAnimationIos(ref, setOnScreen);
const animation = onScreen
  ? imageScale(1.5).animate
  : imageScale(0.5).initial;

const onRecaptchaChange = (token) => {
  setRecaptchaToken(token);
};

  return (
    <Fragment>
      <Toaster/>
      <div className="form-back-drop"></div>
      <section className="hidden-bar">
        <div className="inner-box text-center">
          <div className="cross-icon">
            <span className="fa fa-times" />
          </div>
          <div className="title">
            <h4>Get Appointment</h4>
          </div>
          {/*Appointment Form*/}
          <div className="appointment-form">
            <form
            ref={formRef}
            onSubmit={formik.handleSubmit}
            method="POST"
            >
              <div className="form-group">
              <InputComponent2
                  required={true}
                  className="w-[100%] border-b-[5px] border-gray-300 text-gray-800 outline-none"
                  value="name"
                  placeholder="Full Name"
                  formik={formik}
                />
              </div>
              <div className="form-group">
              <InputComponent2
                          required={true}
                          className=" border-b-[5px] border-gray-300  w-[100%]  text-gray-800 outline-none"
                          value="email"
                          placeholder="Email Address"
                          formik={formik}
                  />
              </div>
              <div className="form-group">
              <PhoneInputCustom2
                  formik={formik}
                  className=" "
                />
              </div>

          {/*}    <div className="form-group">
              <SelectComponent2
                  className="mb-2  border-b-[5px] border-gray-300"
                  mobileWidth="max-width"
                  required={true}
                  selectWidth="17.5rem"
                  isMobile={isMobile}
                  defaultValue={formik.values["selectedOption"]}
                  value="selectedOption"
                  onChange={(selectedOption) => {
                    formik.setFieldValue(
                      "selectedOption",
                      selectedOption
                        ? selectedOption.map((option) => option.value)
                        : [],
                    );
                    setSelectedOption(selectedOption);
                  }}
                  formikTouched={formik.touched["selectedOption"]}
                  formikError={formik.errors["selectedOption"]}
                  options={options}
                  placeholder="Choose Services"
                  aria-label="Choose Services"
                  instanceId="react-select-services"
                  multi={true}
                  formik={formik}
                  value={selectedOption}
                />
              </div>
              <div className="form-group">
              <SelectComponent2
                  className="mb-2  border-gray-300"
                  required={true}
                  selectWidth="max-width"
                  isMobile={isMobile}
                  defaultValue={formik.values["selectedOptionBudget"]}
                  onChange={(selectedOptionBudget) => {
                    formik.setFieldValue(
                      "selectedOptionBudget",
                      selectedOptionBudget ? selectedOptionBudget.value : "",
                    );
                    setSelectedOptionBudget(selectedOptionBudget);
                  }}
                  stringValue="selectedOptionBudget"
                  options={BudgetValues}
                  placeholder="Budget"
                  instanceId="react-select-budget"
                  label="budget"
                  multi={false}
                  mobileWidth="19.5rem"
                  formik={formik}
                  value={selectedOptionBudget}
                  formikTouched={formik.touched["selectedOptionBudget"]}
                  formikError={formik.errors["selectedOptionBudget"]}
                />
              </div> */}
              <div className="form-group">
              <textarea
                    name="message"
                    id="message"
                    className="   txtfieldscontact"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    rows={4}
                    placeholder="Type your message here"
                  />
                  {formik.touched.message && formik.errors.message ? (
                    <div className="error">{formik.errors.message}</div>
                  ) : null}
              </div>

              <div className="form-group">
                <button type="submit" className="theme-btn">
                {loading ? 'Submitting...' : 'Submit now' }  
                </button>
              </div>
            </form>
          </div>
          {/*Social Icons*/}
          <div className="fontzz">
            <a href="https://twitter.com/techquisiteUSA" target="_blank">
              <i className="fab fa-twitter" />
            </a>
            <a href="https://www.facebook.com/techquisiteUSA" target="_blank">
              <i className="fab fa-facebook-f" />
            </a>
            <a href="https://www.instagram.com/techquisiteUSA/" target="_blank">
              <i className="fab fa-instagram" />
            </a>
            <a href="#">
              <i className="fab fa-pinterest-p" />
            </a>
            <a href="#">
              <i className="fab fa-behance" />
            </a>
            <a href="#">
              <i className="fab fa-dribbble" />
            </a>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default SideBar;
