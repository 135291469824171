import { useEffect, useState } from "react";

const UseAnimationIos = (ref, setOnScreen) => {
  const [hasBeenSeen, setHasBeenSeen] = useState(false);

  useEffect(() => {
    const currentRef = ref.current;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !hasBeenSeen) {
            setOnScreen(true);
            setHasBeenSeen(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 },
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [ref, hasBeenSeen, setOnScreen]);
};

export default UseAnimationIos;
