import * as Yup from "yup";

const emailSchema = Yup.string()
  .email("Invalid email format")
  .required("Email is required")
  .test(
    "domain-length",
    "The domain part must be at least 4 characters long",
    (value) => {
      if (typeof value !== "string") return false;
      const parts = value.split("@");
      const domainPart = parts.length === 2 ? parts[1] : "";
      return domainPart.length >= 4;
    },
  )
  .test("domain-contains-dot", "The domain part must contain a .", (value) => {
    if (typeof value !== "string") return false;
    const parts = value.split("@");
    const domainPart = parts.length === 2 ? parts[1] : "";
    return domainPart.includes(".");
  });

const formValidationSchema = Yup.object({
  name: Yup.string().required("Required"),
  email: emailSchema,
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^\+?[0-9]+$/, "Invalid phone number")
    .min(9, "Phone number must be at least 9 characters"),
  //selectedOptionBudget: Yup.string().required("required"),
  //selectedOption: Yup.array().min(1, "Required"),
  // business: Yup.string().required('Required'),
});

const getStartedValidationSchema = Yup.object({
  name: Yup.string().required("Required"),
  email: emailSchema,
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^\+?[0-9]+$/, "Invalid phone number"),
  selectedOptionBudget: Yup.string().required("required"),
  selectedOption: Yup.array().min(0, "Required"),
  // business: Yup.string().required('Required'),
});

export { formValidationSchema, getStartedValidationSchema };
