// useClientIP.js
import { useEffect, useState } from "react";

const useClientIP = () => {
  const [ipAddress, setIpAddress] = useState("");

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIpAddress(data.ip))
      .catch((error) => console.error("Error fetching IP:", error));
  }, []);

  return ipAddress;
};

export default useClientIP;
